var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 ",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","align":"left"}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.loadActualizacionAporte()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","rules":_vm.rules.required.concat(
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))
                          ) || 'Formato incorrecto'},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","rules":_vm.rules.required.concat(
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))
                          ) || 'Formato incorrecto'},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsActualizacAportesEsperados,"loading":_vm.isLoading,"search":_vm.search,"item-key":"procesoImportacionId"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"4","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.nuevaActAporteEsperado()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.usuarioAnula.length > 0},on:{"click":function($event){return _vm.toggleModalSeeAportesEsperados(item.procId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])]),(_vm.canAnular)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.usuarioAnula.length > 0 || !item.ultimoProceso},on:{"click":function($event){return _vm.anularProcesoActualizacion(item.procId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])]):_vm._e()]}}],null,true)})],1)],1),_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2","md":_vm.showHelp ? 8 : 11,"align":"end"}},[_c('v-btn',{staticClass:"fontsize p-2",attrs:{"color":"primary","outlined":"","disabled":_vm.itemsActualizacAportesEsperados
            ? _vm.itemsActualizacAportesEsperados.length == 0
            : ''},on:{"click":_vm.exportLista}},[_vm._v(" Exportar lista completa ")])],1)],1),(_vm.modalVerDetalleAportesEsperados)?_c('v-dialog',{attrs:{"max-width":"90%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalVerActualizacion($event)}},model:{value:(_vm.modalVerDetalleAportesEsperados),callback:function ($$v) {_vm.modalVerDetalleAportesEsperados=$$v},expression:"modalVerDetalleAportesEsperados"}},[_c('VerDetalleActualizacionAporte',{attrs:{"procId":_vm.procId},on:{"closeModalVerActualizacion":_vm.closeModalVerActualizacion}})],1):_vm._e(),(_vm.modalNuevoProcesoImportacion)?_c('v-dialog',{attrs:{"max-width":"70%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalNuevoProcesoImportacion($event)}},model:{value:(_vm.modalNuevoProcesoImportacion),callback:function ($$v) {_vm.modalNuevoProcesoImportacion=$$v},expression:"modalNuevoProcesoImportacion"}},[_c('ImportarAportesEsperados',{on:{"toggleModalNuevoProcesoImportacion":_vm.toggleModalNuevoProcesoImportacion}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"confirmButtonText":'Anular',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmAnularProceso()}}}),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirmDialog,"openConfirm":_vm.openConfirmDialog},on:{"update:openConfirm":function($event){_vm.openConfirmDialog=$event},"update:open-confirm":function($event){_vm.openConfirmDialog=$event},"onConfirm":function($event){return _vm.exportActualizacionesAnuladasConErrores(_vm.aporteEsperadoAnuladoConErrores)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }