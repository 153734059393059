<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 "
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col cols="2" align="right" class="py-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadActualizacionAporte()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Fecha desde -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected))
                            ) || 'Formato incorrecto'
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected))
                            ) || 'Formato incorrecto'
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsActualizacAportesEsperados"
            :loading="isLoading"
            class="elevation-1"
            :search="search"
            item-key="procesoImportacionId"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="nuevaActAporteEsperado()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.usuarioAnula.length > 0"
                    @click="toggleModalSeeAportesEsperados(item.procId)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>
              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.usuarioAnula.length > 0 || !item.ultimoProceso"
                    @click="anularProcesoActualizacion(item.procId)"
                  >
                    {{ anularIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template></v-data-table
          >
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" :md="showHelp ? 8 : 11" align="end">
        <v-btn
          color="primary"
          outlined
          class="fontsize p-2"
          :disabled="
            itemsActualizacAportesEsperados
              ? itemsActualizacAportesEsperados.length == 0
              : ''
          "
          @click="exportLista"
        >
          Exportar lista completa
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalVerDetalleAportesEsperados"
      v-if="modalVerDetalleAportesEsperados"
      @keydown.esc="closeModalVerActualizacion"
      max-width="90%"
      persistent
    >
      <VerDetalleActualizacionAporte
        @closeModalVerActualizacion="closeModalVerActualizacion"
        :procId="procId"
      ></VerDetalleActualizacionAporte>
    </v-dialog>
    <v-dialog
      v-model="modalNuevoProcesoImportacion"
      v-if="modalNuevoProcesoImportacion"
      @keydown.esc="toggleModalNuevoProcesoImportacion"
      max-width="70%"
      persistent
    >
      <ImportarAportesEsperados
        @toggleModalNuevoProcesoImportacion="toggleModalNuevoProcesoImportacion"
      ></ImportarAportesEsperados>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmAnularProceso()"
    />
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="
        exportActualizacionesAnuladasConErrores(aporteEsperadoAnuladoConErrores)
      "
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mapActions } from "vuex";
import VerDetalleActualizacionAporte from "@/views/modules/cuotas/devengamientos/VerDetalleActualizacionAporte.vue";
import ImportarAportesEsperados from "@/views/modules/cuotas/devengamientos/ImportarAportesEsperados.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";

export default {
  name: "ActualizacionAportesEsperados",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    VerDetalleActualizacionAporte,
    ImportarAportesEsperados,
    FiltersSelected,
    Ayuda,
    ConfirmDialog
  },

  data() {
    return {
      title: enums.titles.ACTUALIZACION_APORTES_ESP,
      optionCode: enums.webSiteOptions.APORTES_ESPERADOS,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      calendarIcon: enums.icons.CALENDAR,
      textConfirmDialog: "",
      openConfirmDialog: false,
      menu1: false,
      menu2: false,
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      isFormValid: true,
      rules: rules,
      search: "",
      routeToGo: "ImportesADevengar",
      titleDelete: "¿Desea anular la actualización?",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      anularIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      expanded: [],
      showFilters: true,
      filtersApplied: [],
      itemsActualizacAportesEsperados: [],
      headers: [
        {
          text: "N° de proceso",
          align: "start",
          value: "procId",
          sortable: false
        },
        {
          text: "Fecha alta",
          align: "start",
          value: "fechaAlta",
          sortable: false
        },
        {
          text: "Usuario alta",
          value: "usuarioAlta",
          sortable: false
        },
        {
          text: "Proceso de actualización",
          align: "start",
          value: "rutaArchivo",
          sortable: false
        },
        {
          text: "Período",
          value: "periodoApoEsp",
          sortable: false
        },
        {
          text: "Porcentaje",
          align: "end",
          value: "porcentajeApoEsp",
          sortable: false
        },
        {
          text: "Fecha actualización",
          value: "fechaAnula",
          sortable: false
        },
        {
          text: "Usuario actualización",
          value: "usuarioAnula",
          align: "center",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      isLoading: false,
      canCreate: false,
      canAnular: false,
      canAnularNuevo: false,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      canBuscarAportes: false,
      canImportarAportes: false,
      procesoImportacionId: null,
      modalVerDetalleAportesEsperados: false,
      showDeleteModal: false,
      modalNuevoProcesoImportacion: false,
      idToAnular: null,
      aporteEsperadoAnuladoConErrores: []
    };
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getFechas();
    this.loadActualizacionAporte();
    if(this.$route.params?.procIdNuevo){
      this.toggleModalSeeAportesEsperados(this.$route.params?.procIdNuevo)
    }
  },
  methods: {
    ...mapActions({
      fetchProcesosAportesEspByParams:
        "configuracion/fetchProcesosAportesEspByParams",
      anularProcesoActualizAporteEsperado:
        "configuracion/anularProcesoActualizAporteEsperado",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_AUMENTO_APORTE_ESPERADO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.ANULAR_APORTE_ESPERADO:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions.BUSCAR_APORTES_ESPERADOS:
            this.canBuscarAportes = true;
            break;
          case enums.modules.adminSistema.permissions
            .IMPORTAR_APORTES_ESPERADOS:
            this.canImportarAportes = true;
            break;
          case enums.modules.adminSistema.permissions
            .ANULAR_NUEVO_APORTE_ESPERADO:
            this.canAnularNuevo = true;
            break;
          default:
            break;
        }
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getFechas() {
      let now = new Date();
      let año = now.getFullYear();
      let mes = now.getMonth() + 1;
      let dia = now.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      let one = new Date(now.setMonth(now.getMonth() - 1));
      let oneAño = one.getFullYear();
      let oneMes = one.getMonth() + 1;
      let oneDia = one.getDate();
      oneDia < 10 ? (oneDia = `0${oneDia}`) : oneDia;
      oneMes < 10 ? (oneMes = `0${oneMes}`) : oneMes;
      this.fechaDesde = `${oneAño}-${oneMes}-${oneDia}`;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    exportLista() {
      let result = [];
      this.itemsActualizacAportesEsperados?.forEach(x =>
        result.push({
          ["N° de proceso"]: x.procId,
          ["Fecha de alta"]: x.fechaAlta,
          ["Usuario alta"]: x.usuarioAlta,
          ["Archivo importado"]: x.rutaArchivo,
          ["Período"]: x.periodoApoEsp,
          ["Porcentaje"]: x.porcentajeApoEsp,
          ["Fecha anulado"]: x.fechaAnula,
          ["Usuario anulado"]: x.usuarioAnula
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Listado de actualización de aportes");
    },
    nuevaActAporteEsperado() {
      this.$router.push({
        name: "NuevaActualizacionAportesEsperados",
        params: { canAnularNuevo: this.canAnularNuevo }
      });
    },
    async loadActualizacionAporte() {
      (this.showFilters = false), (this.isLoading = true);
      this.customizeFiltersApplied();
      const data = {
        fechaDesde: this.parseDateToIso(this.fechaDesdeSelected),
        fechaHasta: this.parseDateToIso(this.fechaHastaSelected)
      };
      const res = await this.fetchProcesosAportesEspByParams(data);
      this.itemsActualizacAportesEsperados = res;
      this.isLoading = false;
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 0, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
    },
    toggleModalSeeAportesEsperados(procId) {
      this.modalVerDetalleAportesEsperados = true;
      this.procId = procId;
    },
    closeModalVerActualizacion() {
      this.modalVerDetalleAportesEsperados = false;
    },
    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.loadActualizacionAporte();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    anularProcesoActualizacion(id) {
      this.showDeleteModal = true;
      this.idToAnular = id;
    },
    async confirmAnularProceso() {
      const response = await this.anularProcesoActualizAporteEsperado({
        procId: this.idToAnular
      });
      const searchErrors = response.data.data;
      if (searchErrors == 0) {
        this.setAlert({ type: "success", message: "Anulado con éxito." });
        this.showDeleteModal = false;
        this.loadActualizacionAporte();
      } else {
        this.openConfirmDialog = true;
        this.textConfirmDialog =
          "La actualización posee errores, ¿desea exportar el detalle a Excel?";
      }
    },
    exportActualizacionesAnuladasConErrores() {
      let result = [];
      this.aporteEsperadoAnuladoConErrores?.forEach(x =>
        result.push({
          ["N° de grupo familiar"]: x.grupo,
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.docId,
          ["Apellido y nombre"]: x.apellidoNombre,
          ["Importe"]: x.importe,
          ["Período desde"]: x.periodoDesde,
          ["Período hasta"]: x.periodoHasta,
          ["Importe"]: x.importe,
          ["Observaciones"]: x.observaciones
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado lista"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle aportes esperados con errores"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -webkit-moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
</style>
