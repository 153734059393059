<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        <v-col cols="8">Importar archivo aportes esperados</v-col>
      </v-card-title>
      <v-card-text>
        <!-- Proceso de importación -->
        <v-row cols="12" class="py-0">
          <v-col cols="7">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              id="fileUpload"
              type="file"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              color="primary"
              class="no-upper-case to-right py-4"
              small
              outlined
              @click="openModalSeeFormatoArchivo()"
            >
              Ver formato
            </v-btn></v-col
          >
          <v-btn class="mt-3" outlined @click="closeModalExcel()">
            Cerrar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headers"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "ImportarAportesEsperados",
  props: {
    itemsNuevaActualizacionAportes: {
      type: Array,
      required: false,
      default: []
    }
  },
  directives: { mask },
  components: { FormatoArchivoDialog },
  data() {
    return {
      rules: rules,
      loading: false,
      excelData: [],
      modalExportarExcel: false,
      archivoEnProceso: false,
      file: null,
      registrosError: [],
      registrosOk: [],
      fileNameArchivo: null,
      formatoTitle: "Formato de archivo",
      headers: [],
      datosFormatoArchivo: [],
      showSeeFormatModal: false,
      dataToExport: {
        formatoName: "Importación aportes esperados modelo",
        data: [
          {
            //nose que datos debe contener cada campo
            ["Grupo familiar"]: "(Campo opcional)",
            ["CUIL"]: "(Campo opcional)",
            ["Tipo de doc."]: "(Campo opcional)",
            ["N° de doc."]: "(Campo requerido)",
            ["Importe"]: "(Campo requerido)",
            ["Período desde"]: "(Campo requerido)",
            ["Período hasta"]: "(Campo requerido)"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions({
      getFormatoProcesoImportacionAportesEsperados:
        "configuracion/getFormatoProcesoImportacionAportesEsperados",
      saveImportacionArchivoAportesEsperados:
        "configuracion/saveImportacionArchivoAportesEsperados",
      logo: "devengamientos/logo",
      setAlert: "user/setAlert"
    }),
    closeModalExcel() {
      this.$emit("toggleModalImportar");
    },

    async importExcel() {
      if (this.file) {
        try {
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.fileNameArchivo = document.getElementById(
              "fileUpload"
            ).files[0].name;
            let excelDataTranslated = [];
            this.excelData[0].filas.forEach(x => {
              excelDataTranslated.push({
                nroGrupo: x["Grupo familiar"],
                cUIL: x["CUIL"],
                tipoDoc: x["Tipo de doc."],
                nroDoc: x["N° de doc."],
                importeNuevo: x["Importe"],
                periodoDesde: x["Período desde"],
                periodoHasta: x["Período hasta"]
              });
            });
            this.saveProcesoImportacion(excelDataTranslated);
          } else {
            setTimeout(this.importExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loading = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    async saveProcesoImportacion(datosProceso) {
      const data = {
        nombreArchivo: this.fileNameArchivo,
        datosProcesoImportacion: datosProceso
      };
      const response = await this.saveImportacionArchivoAportesEsperados(data);
      if (response.status === 200) {
        this.registrosOk = response.data.data.filter(x => x.hasError === false);
        this.registrosError = response.data.data.filter(
          x => x.hasError === true
        );
        if (this.registrosError.length > 0) {
          this.modalExportarExcel = true;
        } else {
          this.setAlert({ type: "success", message: "Procesado con éxito." });
          this.closeModalExcel();
        }
        if (this.registrosOk.length > 0) {
          this.registrosOk.forEach(aporte => {
            this.itemsNuevaActualizacionAportes.push(aporte);
          });
          this.$emit("disableFilters", this.fileNameArchivo);
        }
      }
    },
    getArchivo(rowsToIgnore) {
      if (this.archivoEnProceso == false) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(this.file, rowsToIgnore);
        }
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    exportExcelError() {
      let result = [];
      this.registrosError.forEach(x =>
        result.push({
          ["N° de grupo"]: x.grupo,
          ["CUIL"]: x.cuil,
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.docId,
          ["Nuevo importe"]: new Intl.NumberFormat("de-DE").format(
            x.importeNuevo
          ),
          ["Período desde"]: x.periodoDesde,
          ["Período hasta"]: x.periodoHasta,
          ["Error"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Errores registros");
      this.closeModalExcel();
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.getFormatoProcesoImportacionAportesEsperados();
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        },
        {
          text: "Error",
          value: "mensajeError",
          align: "start",
          sortable: false
        }
      ];

      this.showSeeFormatModal = true;
    }
  }
};
</script>
