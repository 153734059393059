<template>
  <v-dialog
    v-model="openFormatoArchivoDialog"
    :max-width="maxWidth"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-container>
        <v-card-title class="primary--text py-0 pl-1">
          <v-col> {{ formatoTitle }}</v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="datosFormatoArchivo"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:[`item.requerido`]="{ item }">
              <v-icon v-if="item.requerido" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-row class="justify-end mr-4">
          <v-spacer></v-spacer>
          <v-col class="py-3">
            <v-btn
              color="primary"
              class="to-right py-4"
              small
              outlined
              @click="exportModelo"
            >
              Descargar formato
            </v-btn></v-col
          >
          <v-card-actions class="pr-0">
            <v-spacer></v-spacer>
            <v-btn outlined @click="close"> Cerrar </v-btn>
          </v-card-actions>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "FormatoArchivoDialog",
  props: {
    formatoTitle: { type: String, required: true, default: "Ver formato" },
    openFormatoArchivoDialog: { type: Boolean, required: true },
    headers: { type: Array, required: true, default: [] },
    datosFormatoArchivo: { type: Array, required: true, default: [] },
    dataToExport: { type: Object, required: true, default: {} },
    maxWidth: { type: String, required: false, default: "80%" }
  },
  data() {
    return {
      search: "",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      dialogFormatoArchivo: false
    };
  },
  methods: {
    close() {
      this.dialogFormatoArchivo = false;
      this.$emit("update:openFormatoArchivoDialog", false);
    },
    async exportModelo() {
      let formato = {
        filas: this.dataToExport.data,
        hoja: "Modelo"
      };
      helpersExcel.excelExport(formato, this.dataToExport.formatoName);
    }
  }
};
</script>
