<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ titleDetalle + procId }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="itemsDetalleActualizacionAporte"
          item-key="interesesACobrarId"
          :search="search"
          :loading="isLoading"
        >
        <template v-slot:[`item.tipoYDoc`]="{ item }">{{ item.tipoDoc }} - {{ item.docId }}</template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            <span>{{ addDecimals(item.importe) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip top max-width="35%">
            <template v-slot:activator="{attrs }">
              <v-btn
                color="primary"
                class="py-4 fontsize"
                v-bind="attrs"
                small
                outlined
                :disabled="itemsDetalleActualizacionAporte.length < 1"
                @click="exportExcel"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index.js";

export default {
  name: "VerDetalleActualizacionAporte",
  props: {
    procId: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    search: null,
    searchIcon: enums.icons.SEARCH,
    titleDetalle: "Detalle de actualización de aportes esperados. Proceso N°: ",
    isLoading: false,
    headers: [
        {
          text: "N° de grupo familiar",
          value: "grupo",
          sortable: false
        },
        {
          text: "Tipo Y N° de documento",
          value: "tipoYDoc",
          sortable: false
        },
        
        {
          text: "Apellido y nombre",
          align: "start",
          value: "apellidoNombre",
          sortable: false
        },
        {
          text: "Período desde",
          value: "periodoDesde",
          sortable: false
        },
        {
          text: "Período hasta",
          value: "periodoHasta",
          sortable: false
        },
        {
          text: "Importe",
          value: "importe",
          align: "end",
          sortable: false
        },
    ],
    itemsDetalleActualizacionAporte: []
  }),
  created() {
    this.loadDetalleAporteEsperado();
  },
  methods: {
    ...mapActions({
      getProcesoAporteEsperadoById: "configuracion/getProcesoAporteEsperadoById",
      setAlert: "user/setAlert"
    }),
    async loadDetalleAporteEsperado() {
      this.isLoading = true;
      const response = await this.getProcesoAporteEsperadoById(this.procId);
      this.itemsDetalleActualizacionAporte = response;
        this.isLoading = false;
      if(this.itemsDetalleActualizacionAporte.length < 1){
         this.setAlert({
          type: "warning",
          message: "El registro no posee actualización"
        });
        
      }
    },
    closeModal() {
      this.$emit("closeModalVerActualizacion");
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    exportExcel() {
      let result = [];
      this.itemsDetalleActualizacionAporte.forEach(x =>
        result.push({
          ["N° de grupo"]: x.grupo,
          ["Tipo doc."]: x.tipoDoc,
          ["N° doc."]: x.docId,
          ["Apellido y nombre"]: x.apellidoNombre,
          ["Período desde"]: x.periodoDesde,
          ["Período hasta"]: x.periodoHasta,
          ["Importe"]: new Intl.NumberFormat('de-DE').format(x.importe),
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalle"
      };
      helpersExcel.excelExport(formato, "Detalle de actualización de aporte");
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
